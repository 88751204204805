export const packages = [
  {
    name: "Bronze - 30K",
    price: 30000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "50-Sheet 14 x 40 Album with Digital Cover",
      "64GB Pendrive",
      "Coverage for 2-Day Programme",
    ],
    excluded: [
      "Candid Photography",
      "LED Screen (8x12) at Venue",
      "Video Highlights",
      "Drone Shoot",
      "Video Teaser",
      "Ring Ceremony Function",
      "Cinematic Video for Couples",
      "Pre-Wedding Shoot",
      "Custom Photo Album Design",
      "Live Streaming of Events",
    ],
  },
  {
    name: "Silver - 50K",
    price: 50000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "50-Sheet 14 x 40 Album with Leather Cover",
      "Video Highlights",
      "64GB Pendrive",
      "LED Screen (8x12) at Venue",
      "Coverage for 2-Day Programme",
    ],
    excluded: [
      "Candid Photography",
      "Cinematic Video for Couples",
      "Drone Shoot",
      "Video Teaser",
      "Ring Ceremony Function",
      "Live Streaming of Events",
      "Pre-Wedding Shoot",
      "Custom Photo Album Design",
    ],
  },
  {
    name: "Gold - 70K",
    price: 70000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "80-Sheet 14 x 40 Album with Leather Cover",
      "Drone Shoot",
      "64GB Pendrive",
      "LED Screen (8x12) at Venue",
      "Video Teaser",
      "Video Highlights",
      "Candid Photography",
      "Custom Photo Album Design",
    ],
    excluded: [
      "Cinematic Video for Couples",
      "Ring Ceremony Function",
      "Pre-Wedding Shoot",
      "Extra Cameramen",
      "Live Streaming of Events",
    ],
  },
  {
    name: "Platinum - 100K",
    price: 100000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "80-Sheet 14 x 40 Album with Leather Cover",
      "Drone Shoot",
      "64GB Pendrive",
      "LED Screen (8x12) at Venue",
      "Video Teaser",
      "Video Highlights",
      "Cinematic Videography (Whole Event)",
      "Candid Photography (Whole Event)",

      "Custom Photo Album Design",
    ],
    excluded: [
      "Ring Ceremony Function",
      "Pre-Wedding Shoot",
      "Live Streaming of Events",
      "Additional Pendrive Copies",
    ],
  },
  {
    name: "Diamond - 1.2 LAC",
    price: 120000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "100-Sheet 14 x 40 Album with Leather Cover",
      "Drone Shoot",
      "64GB Pendrive",
      "LED Screen (8x12) at Venue",
      "Video Teaser",
      "Video Highlights",
      "Cinematic Videography",
      "Candid Photography (Marriage Only)",
      "Ring Ceremony Programme",
      "Custom Photo Album Design",
      "Extra Cameramen",
      "Backup Copies After 6 Months",
    ],
    excluded: [
      "Pre-Wedding Shoot",
      "Live Streaming of Events",
      "Video cinematics at ring ceremony",
    ],
  },
  {
    name: "Prime - 1.5 LAC",
    price: 150000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "100-Sheet 14 x 40 Album with Leather Cover",
      "(Extra High-Quality album Paper)",
      "Drone Shoot",
      "64GB Pendrive",
      "LED Screen (8x12) at Venue",
      "Video Teaser",
      "Video Highlights",
      "Cinematic Videography",
      "Candid Photography",
      "Extra 2 Cameramen for Capturing More Moments",
      "Ring Ceremony with Candid Photography and Cinematography",
      "Custom Photo Album Design",
      "Backup Copies After 6 Months",
    ],
    excluded: ["Pre-Wedding Shoot", "Live Streaming of Events"],
  },
  {
    name: "Exclusive - 2 LAC",
    price: 200000,
    included: [
      "Full HD Photos (RAW Data)",
      "Full HD Videos (Master + Edited Copy)",
      "130-Sheet 14 x 40 Album with Leather Cover (Extra High-Quality Paper)",
      "Drone Shoot",
      "64GB Pendrive",
      "2 x LED Screens (8x12) at Venue",
      "Video Teaser",
      "Video Highlights",
      "Cinematic Videography",
      "Candid Photography",
      "Custom Photo Album Design",
      "Extra 2 Cameramen for Capturing More Moments",
      "All Functions Included (Pre-Wedding Shoots, Ring Ceremony) with Candid and Cinematography",
      "Coverage for 3-Day Programme",
    ],
    excluded: ["Live Streaming of Events"],
  },
];

export const imageUrls = [
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686466353/portrait/0000_llcji3.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686466160/portrait/00_hqiap1.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686465913/portrait/DSC_5343_gvmpur.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686465335/portrait/0Y1A1089_uumeha.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405233/portrait/AC2A1543_kyr4w2.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405232/portrait/0Y1A0698_ypzjep.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405231/portrait/VSPL9482_gakabj.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405255/landscape/0Y1A0751_fslltp.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405254/landscape/AC2A1013_zlrcex.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405255/landscape/VSPL9247_a1ycbn.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405253/landscape/DSC_5320_qcdvmo.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405253/landscape/DSC_5340_fnq6un.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405228/portrait/AC2A1481_yzdaga.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405227/portrait/0Y1A1410_hz9i4r.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405225/portrait/AC2A1223_bf0idt.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405223/portrait/DSC_9616_wwyyvy.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405225/portrait/AC2A9203_k4miem.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405223/portrait/0Y1A9907_rd6lec.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405223/portrait/AC2A0887_xxbwba.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405219/portrait/0Y1A0738_vrcfzd.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405222/portrait/0Y1A0741_zoapni.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405217/portrait/DSC_9576_oecdzv.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405263/landscape/0Y1A1069_ker0zb.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405262/landscape/AC2A9185_lpqnxc.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405251/landscape/AC2A0851_yfsorb.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405252/landscape/0Y1A9929_njwuve.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405254/landscape/AC2A0717_voaxhf.jpg",
  "https://res.cloudinary.com/dnvjct2if/image/upload/v1686405258/landscape/AC2A8825_etip5g.jpg",
];

export const weddingFilmsData = [
  {
    title: "ABHISHEK WEDS RISHIKA",
    videoId: "https://www.youtube.com/embed/T_gFHaNrVQo",
    description:
      "The contentment we see on our client’s face is what satisfies our souls. Capturing those joyous moments and transforming them into cherished memories is not just our profession but our passion. With every click, we strive to immortalize the love and happiness that define your special day. Our team works tirelessly to ensure that every detail, emotion, and celebration is beautifully captured, creating a visual narrative that you'll treasure forever.",
  },
  {
    title: "AKASH AND SRISHTI",
    videoId: "https://www.youtube.com/embed/QwOmZqzrnU8",
    description:
      "Wedding photography to us is to witness your beautiful story. Through our lens, we capture the essence of your love, the purity of emotions, and the beauty of your journey together. Every shot reflects the unique bond you share, creating a timeless collection that narrates the tale of your union.",
    isReversed: true,
  },
  {
    title: "POORAB AND SMRITI",
    videoId: "https://www.youtube.com/embed/N7aQF84dxXo",
    description:
      "Witness the best moments of Punit and Harshita. Our commitment to excellence and passion for storytelling guide us in crafting visual masterpieces that not only showcase the events but also encapsulate the emotions and sentiments of your wedding day. Allow us to turn your love story into a work of art.",
    isReversed: false,
  },
  {
    title: "ABHISHEK AND RISHIKA PRE WEDDING",
    videoId: "https://www.youtube.com/embed/Aza1a8tQfHE",
    description:
      "Get the best Prewedding shoot done with us. What are you waiting for? Our pre-wedding shoots are a blend of creativity, romance, and personalized storytelling. We capture the essence of your unique love story, creating a cinematic journey that beautifully sets the tone for your upcoming wedding.",
    isReversed: true,
  },
  {
    title: "RITESH JHA AND SAPNA JHA",
    videoId: "https://www.youtube.com/embed/E9fzUA4TLpg",
    description:
      "Witness the heritage of Rao Sahab wedding. Each frame of our photography and videography reflects the rich heritage, cultural nuances, and timeless traditions of your wedding. We take pride in preserving these moments, ensuring that your wedding story becomes a cherished legacy for generations to come.",
    isReversed: false,
  },
  {
    title: "NEERAJ AND SIMMU",
    videoId: "https://www.youtube.com/embed/NYuR7NnOMMI",
    description:
      "We listen to what you want and then create beautiful memories of your dream wedding. Presenting you Haldi ceremony of Saurabh and Chandni. Our personalized approach and attention to detail allow us to capture the uniqueness of your Haldi ceremony, preserving the vibrant colors, joyous moments, and the beauty of this traditional celebration.",
    isReversed: true,
  },
];

