import React, { useState, useEffect } from "react";

function AdminPanel() {
  return (
    <div>
      <h1>Admin Panel Data</h1>
    </div>
  );
}

export default AdminPanel;
